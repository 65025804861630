<template>
  <div class="global_theme_ui_page">
    <div class="">
      <SharePrintCard
        :title="`${localization('Your Personal Work Profile (PWP)')}`"
        :text="`${localization('The Personal Work Profile (PWP) is an essential questionnaire step to better understand your behaviors and career goals in order to suggest personalized strategies.')}`"
        :displayPrint="false"
        :displayShare="false"
        class="custom_text"
      ></SharePrintCard>

      <!-- Use Your Learning Patterns -->
      <div class="learner_patterns_wrapper ">
        <div class="main_cards mt-3">
          <div class="card_item">
            <div class="mb-3">
              <img class="img" src="@/assets/new-theme/pwp-1.png" alt="" />
            </div>
            <div class="mb-4">
              <span class="title mb-2 d-block">
                {{ localization("Career matching") }}
              </span>
              <p class="mb-0 description">
                {{ localization("Understanding your behaviors and career goals provides us with insights to suggest personalized strategies that helps you in career opportunity.") }}
              </p>
            </div>
          </div>
          <div class="card_item">
            <div class="mb-3">
              <img class="img" src="@/assets/new-theme/pwp-2.png" alt="" />
            </div>
            <div class="mb-4">
              <span class="title mb-2 d-block">
                {{ localization("Take the time you need") }}
              </span>
              <p class="mb-0 description">
                {{ localization("While there are no right or wrong answers, there are answers that are more accurate to who you are than others.") }}
              </p>
            </div>
          </div>
          <div class="card_item">
            <div class="mb-3">
              <img class="img" src="@/assets/new-theme/pwp-3.png" alt="" />
            </div>
            <div class="mb-4">
              <span class="title mb-2 d-block">
                {{ localization("Be as accurate as possible") }}
              </span>
              <p class="mb-0 description">
                {{ localization("Try to be as accurate and detailed as possible in responding to the open-end questions at the end of the questionnaire.") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Use Your Learning Patterns -->

      <div class="mt-5">
        <AppButton
          :withImg="false"
          :text="`${localization('Start the PWP')}`"
          @click="switchToQuestions()"
        ></AppButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PwpCareer",
  methods: {
    switchToQuestions() {
      this.$router.push("/new-theme/pwp-questions");
    }
  }
};
</script>

<style lang="scss" scoped>
.custom_text {
  width: 70%;
}

.learner_patterns_wrapper {
  margin-top: 40px;
  background: #fff;
  width: 100%;
  position: relative;
  &::before {
    position: absolute;
    // top: 10px;
    // right: 0;
    top: -327px;
    right: 68px;
    content: "";
    background-image: url("../../../../assets/new-theme/lci/background2.png");
    background-size: cover;
    width: 800px;
    height: 792px;
    z-index: 1;
  }
  .titles_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .print_share_buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .btn_2 {
    border: 1px solid transparent;
    border-radius: 8px;
    color: #6941c6;
    font-size: 14px;
    height: 41px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: 10px;
    background: #fff;
  }
  .text {
    color: #667085;
    font-weight: 400;
    font-size: 16px;
  }
  .main_cards {
    display: flex;
    justify-content: space-between;
    gap: 4%;
    position: relative;
    z-index: 2;
    .card_item {
      background: #fff;
      border-radius: 8px;
      border: 1px solid #eaecf0;
      padding: 24px;
      width: calc(96% / 3);

      .title {
        color: #101828;
        font-weight: 600;
        font-size: 18px;
      }
      .description {
        color: #667085;
        font-weight: 400;
        font-size: 16px;
      }
      .lci_btn {
        background-color: #f2f4f7;
        color: #6941c6;
        border-radius: 8px;
        padding: 12px 20px;
        border: 1px solid #eaecf0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
</style>
